import {validateSelect} from '@/components/PersonalLoanSoftQuote/validator/addressValidator';

const YEARS_REGEX = /^\d{1,2}$/;
const MONTHS_REGEX = /^\d{1,2}$/;

export const validateTimeStamp = ({ years, months, field }) => {
  const errors = [];

  if (YEARS_REGEX.test(years)) {
    const yearsInt = parseInt(years, 10);

    if (yearsInt < 0 || yearsInt > 99) {
      errors.push("Years must be within 0-99");
    }
  } else if (years !== "") {
    errors.push("Years must be within 0-99");
  }

  if (MONTHS_REGEX.test(months)) {
    const monthsInt = parseInt(months, 10);

    if (monthsInt < 0 || monthsInt > 11) {
      errors.push("Months must be within 0-11");
    }
  } else if (months !== "") {
    errors.push("Months must be within 0-11");
  }

  if (errors.length === 0) {
    const yearsInt = parseInt(years || "0", 10);
    const monthsInt = parseInt(months || "0", 10);

    if (yearsInt === 0 && monthsInt === 0) {
      errors.push(`${field} must be at least 1 month`);
    }
  }

  return errors;
};

export const validateCheckbox = (value, {isEmpty}, message) => {
  if (isEmpty(value)) {
    return message;
  }

  return null;
};

export const timeAtCurrentAddressValidation = ({ years, months }) => validateTimeStamp( {years, months, field: 'Time at current address' });;
export const timeAtCurrentEmploymentValidation = ({ years, months }) => validateTimeStamp( {years, months, field: 'Time at current employment' });;

export const residentialStatusValidation = validateSelect(
  `Select a residential status`,
);

export const incomeFromValidation = validateSelect(
  `Select an income source`,
);

export const occupationValidation = validateSelect(
  `Select an occupation`,
);

export const creditReportingBodyValidation = (value, {isEmpty}) => validateCheckbox( value,{isEmpty}, 'Please read and accept');
export const consentToCreditScoreNotAffectedValidation = (value, {isEmpty}) => validateCheckbox( value,{isEmpty}, 'Please read and accept');
export const consentPrivacyValidation = (value, {isEmpty}) => validateCheckbox( value,{isEmpty}, 'Please read and accept');
