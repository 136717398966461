import styled from '@emotion/styled';
import {BREAKPOINT} from '@latitude/core/utils/constants';
import {Container, List, Text} from 'basis';
import React from 'react';
import {CHANNEL} from '@/components/PersonalLoanSoftQuote/constants/softQuoteConstants';

export const Eligibility = ({channel, isBetterStart=false}) => {
  const OuterContainer = styled.div`
    @media (max-width: ${BREAKPOINT.LG}) {
      margin-top: -16px;
    }
  `;
  const eligibilityDotPointsAU = [
    `a permanent resident of Australia, who is at least 18 years old`,
    `currently employed on a permanent basis and have a regular income`,
    `able to demonstrate a good credit history for the last 5 years`,
    `free from bankruptcy for the last 7 years`,
  ];
  const eligibilityDotPointsNZ = [
    `18 years or over`,
    `a permanent NZ resident`,
    `earning a stable income`,
  ];
  const eligibilityDotPointsBetterStartAU = [
    `a new Latitude Personal Loan customer`,
    `borrowing at least $20,000 for debt consolidation`,
    ...eligibilityDotPointsAU,
  ];
  let title='';
  let eligibilityDotPoints=[];
  switch( channel) {
    case CHANNEL.GEM:
      title = `To make sure a Gem loan is right for you, you must be:`;
      eligibilityDotPoints = eligibilityDotPointsNZ
      break;
    case CHANNEL.AU:
    case CHANNEL.BROKER:
      title = `To make sure a Latitude ${isBetterStart?'Better Start Personal Loan':'loan'} is right for ${(channel === CHANNEL.BROKER)?'your client, they':'you, you'} must be:`;
      eligibilityDotPoints = isBetterStart?eligibilityDotPointsBetterStartAU:eligibilityDotPointsAU;
      break;
    default:
      // The CHANNEL.KIWI member of the CHANNEL enum is not required to be covered, but will show with blank eligibility.

  }
  return (
    <OuterContainer>
      <Container bg="secondary.lightBlue.t25" margin="0" padding="5">
        <Container bg="white" padding="8 5" padding-lg="4">
          <Text margin="0 0 4">
            <strong>
              {title}
            </strong>
          </Text>
          <List>
            {eligibilityDotPoints.map((dotPoint,dotPointIndex) => <List.Item key={dotPointIndex}>{dotPoint}</List.Item>)}
          </List>
        </Container>
      </Container>
    </OuterContainer>
  );
};
