import {REGION} from "../constants/softQuoteConstants";
import {streetTypeAuOptions} from "../constants/streetTypeAUList";
import {streetTypeNzOptions} from "../constants/streetTypeNZList";
import {streetTypeDefaultOptions} from "../constants/streetTypeList";

const _ = require('lodash');

export function isAutoAddressValid(address, region) {
  let isValid = (!_.isEmpty(address.StreetNumber1) || !_.isEmpty(address.LotNumber))
    && !_.isEmpty(address.StreetName)
    && ( !_.isEmpty(address.StreetType) && isStreetTypeValid(address, region))
    && !_.isEmpty(address.Postcode);

  if (region === REGION.NZ) {
    isValid = isValid && !_.isEmpty(address.TownCityMailtown)
  } else {
    isValid = isValid && !_.isEmpty(address.Locality)
      && !_.isEmpty(address.State)
  }

  return isValid;
}

function isStreetTypeValid(address, region) {
  let streetTypes = region === REGION.AU ? streetTypeAuOptions : streetTypeNzOptions;
  let isShortenedStreetTypeValid = false;
  let isFullStreetTypeValid = false;

  streetTypeDefaultOptions.map(({ value, label }) => {
    if (address.StreetType.toUpperCase() === value.toUpperCase()) {
      streetTypes.map(({ value }) => {
        if (label === value) {
          isShortenedStreetTypeValid = true;
        }
      });
    }
  });
  streetTypes.map(({ value }) => {
    if (address.StreetType === value) {
      isFullStreetTypeValid = true;
    }
  });

  return isShortenedStreetTypeValid || isFullStreetTypeValid;
}

export function isAddressPOBox(address) {
  return address.AddressLine.includes('PO Box');
}

export function displayAutoAddressErrorIfAddressInvalid(autoAddress, setAutoAddressErrorMessage, setHasResidentialAddressAutoAddressError, region) {
  let hasError;
  if (isAddressPOBox(autoAddress)) {
    setAutoAddressErrorMessage('We do not accept PO Boxes. Please enter a residential address.');
    setHasResidentialAddressAutoAddressError(true);
    hasError = true;
  } else if (!isAutoAddressValid(autoAddress, region)) {
    setAutoAddressErrorMessage("For this address format, please tick the ‘Can't find your address’ box below and enter your address manually");
    setHasResidentialAddressAutoAddressError(true);
    hasError = true;
  } else {
    setHasResidentialAddressAutoAddressError(false);
    setAutoAddressErrorMessage('');
    hasError = false;
  }
  return hasError;
}

export function isAutoAddressValidOnSubmit(address, region) {
  return address.cantFindAutoAddress
    || (!address.cantFindAutoAddress
      && address.autoAddress
      && address.autoAddress.AddressLine != undefined
      && isAutoAddressValid(address.autoAddress, region)
      );
}
