import { createGlobalStyle } from 'styled-components'

const EmploymentGlobalStyle = createGlobalStyle`
  // fixes overflow bug with residential address popover
  ul[role="listbox"]{
    width: auto;
    position: relative;
   }

  // fixes spacing around 'cant find address' checkbox
  input[name="residentialAddress.cantFindAutoAddress"] + label,
  input[name="previousAddress.cantFindAutoAddress"] + label {
    padding: 0;
    background-color: transparent !important;
    color:#000000 !important;
    rect {
      fill: #ffffff;
    }
  }

  // fixes padding under consent checkboxes when in error state
  input[name="consentToRequestCreditReport"] + label,
  input[name="consentToCreditScoreNotAffected"] + label,
  input[name="consentPrivacy"] + label {
    margin-bottom: 0 !important;
  }
`;

export default EmploymentGlobalStyle
