// Maps collected data into SF required format
/* eslint-disable */

import { purposeOptions } from '../../EstimateRateWidget/MainRateWidget';
import {
  AU_MINIMUM_RESIDENCY_IN_MONTHS,
  COUNTRY, GEM_MINIMUM_RESIDENCY_IN_MONTHS,
  PRODUCT,
  REGION
} from '@/components/PersonalLoanSoftQuote/constants/softQuoteConstants';
import {purposeNzOptions} from '@/components/EstimateRateWidget/MainRateWidget';
import {getLengthOfResidencyInMonths} from "@/components/PersonalLoanSoftQuote/utils/convertTimeStampToMonths";

export function dataMapping(data, region = REGION.AU, product = PRODUCT.LATITUDE) {
  const isBroker = data.brokerNumber !== undefined;
  const channel = isBroker ? 'Broker' : 'Online';

  const loanTerm = (data.repaymentPeriod || sessionStorage.getItem('repaymentPeriod')) ?? 5;
  let loanTermNumber = Number(loanTerm);
  if (isNaN(loanTermNumber)) loanTermNumber = 5;
  let loanTermParam;
  if (loanTerm == '0.5') {
    loanTermParam = '6 months'
  } else if (loanTermNumber % 1 === 0.25){
    // better start - the SF API wants the Loan_Term__c in the payload  to be in the format `2 years (+3 months)`. Valid range in SF API is '1 year (+3 months)' to '7 years (+3 months)'
    loanTermParam = `${Math.floor(loanTermNumber)} year${loanTermNumber === 1.25?'':'s'}(+3 months)`;
  } else {
    loanTermParam = loanTerm + (loanTerm == 1 ? ' year' : ' years');
  }
  const loanPurpose = data.purpose || sessionStorage.getItem('purpose');
  const purposeOpts = product === PRODUCT.GEM ? purposeNzOptions : purposeOptions;
  const loanText = purposeOpts.filter(
    purposeOptions => purposeOptions.value === loanPurpose
  );

  const loanPurposeOther = loanText[0]?.value === 'other' ? (data.purposeOther || sessionStorage.getItem('purposeOther')) : '';

  const loanAmount = (data.loanAmount || sessionStorage.getItem('loanAmount')) ?? '3000';
  const brokerFee = (data.brokerFee || sessionStorage.getItem('brokerFee')) ?? '0';
  const consent =
    data.consentToCreditScoreNotAffected && data.consentToRequestCreditReport
      ? true
      : false;

  const driversLicence = data.hasNoDriverLicence ? '' : data.licenceNumber;

  const yearsAtAddress =
    data.timeAtCurrentAddress.years === ''
      ? '0'
      : data.timeAtCurrentAddress.years;

  const monthsAtAddress =
    data.timeAtCurrentAddress.months === ''
      ? '0'
      : data.timeAtCurrentAddress.months;

  let yearsOfEmployment =
    data.timeAtCurrentEmployment.years === ''
      ? '0'
      : data.timeAtCurrentEmployment.years;

  let monthsOfEmployment =
    data.timeAtCurrentEmployment.months === ''
      ? '0'
      : data.timeAtCurrentEmployment.months;

  if (product === PRODUCT.GEM && (data.incomeFrom !== 'Net Salary/Wages' && data.incomeFrom !== 'Self Employed')) {
    yearsOfEmployment = '';
    monthsOfEmployment = '';
  }

  const dateOfBirth =
    data.dateOfBirth.day.padStart(2, '0') +
    '/' +
    data.dateOfBirth.month.padStart(2, '0') +
    '/' +
    data.dateOfBirth.year;

  let driveLicenceExpiryDate;
  if (product === PRODUCT.GEM && data.expiryDate.day !== '') {
    driveLicenceExpiryDate =
      data.expiryDate.day.padStart(2, '0') +
      '/' +
      data.expiryDate.month.padStart(2, '0') +
      '/' +
      data.expiryDate.year;
  }

  //Rules: Auto address is default, unless checkbox is ticked, then use other address

  //Current autoAddress
  const currentAutoAddress = data.residentialAddress.autoAddress;
  let currentAutoAddressValues;
  if (data.residentialAddress.cantFindAutoAddress == false) {
    currentAutoAddressValues = {
      Residential_Unit_Number__c: currentAutoAddress.UnitNumber,
      Residential_Street_Number__c: currentAutoAddress.StreetNumber1 !== '' ?  currentAutoAddress.StreetNumber1 : currentAutoAddress.LotNumber,
      Residential_Street_Name__c: currentAutoAddress.StreetName,
      Residential_Street_Type__c: currentAutoAddress.StreetType ?? 'Street',
      Residential_Suburb__c: currentAutoAddress.Locality || currentAutoAddress.Suburb,
      Residential_State__c: currentAutoAddress.State,
      Residential_Postcode__c: currentAutoAddress.Postcode,
      Residential_Country__c: region === REGION.AU ? COUNTRY.AU : COUNTRY.NZ,
      Property_Name__c: currentAutoAddress.StreetNumber1 !== '' ? '' : ('Lot ' + currentAutoAddress.LotNumber)
    };
  }

  //Current manualAddress
  const currentManualAddress = data.residentialAddress;
  const currentManualAddressValues = {
    Residential_Unit_Number__c: currentManualAddress.unitNumber,
    Residential_Street_Number__c: currentManualAddress.streetNumber,
    Residential_Street_Name__c: currentManualAddress.streetName,
    Residential_Street_Type__c: currentManualAddress.streetType,
    Residential_Suburb__c: currentManualAddress.suburb,
    Residential_State__c: currentManualAddress.state,
    Residential_Postcode__c: currentManualAddress.postcode,
    Residential_Country__c:  region === REGION.AU ? COUNTRY.AU : COUNTRY.NZ,
    Property_Name__c: currentManualAddress.propertyName
  };
  const nzResidentialCity = {
    Residential_City__c: data.residentialAddress.cantFindAutoAddress ? currentManualAddress.city : currentAutoAddress.TownCityMailtown,
  };
  const lengthOfResidencyInMonths = getLengthOfResidencyInMonths(
    data.timeAtCurrentAddress
  );

  //Previous autoAddress
  const prevAutoAddress = data.previousAddress.autoAddress;
  let prevAutoAddressValues;
  if (data.previousAddress.cantFindAutoAddress == false) {
    prevAutoAddressValues = {
      Previous_Unit_Number__c: prevAutoAddress.UnitNumber,
      Previous_Street_Number__c: prevAutoAddress.StreetNumber1 !== '' ? prevAutoAddress.StreetNumber1 : prevAutoAddress.LotNumber,
      Previous_Street_Name__c: prevAutoAddress.StreetName,
      Previous_Street_Type__c: prevAutoAddress.StreetType ?? 'Street',
      Previous_Suburb__c: prevAutoAddress.Locality || prevAutoAddress.Suburb,
      Previous_State__c: prevAutoAddress.State,
      Previous_Postcode__c: prevAutoAddress.Postcode,
      Previous_Country__c:  region === REGION.AU ? COUNTRY.AU : COUNTRY.NZ,
      Previous_Property_Name__c: prevAutoAddress.StreetNumber1 !== '' ? '' : ('Lot ' + prevAutoAddress.LotNumber)
    };
  }

  //Previous manualAddress
  const prevManualAddress = data.previousAddress;
  const prevManualAddressValues = {
    Previous_Unit_Number__c: prevManualAddress.unitNumber,
    Previous_Street_Number__c: prevManualAddress.streetNumber,
    Previous_Street_Name__c: prevManualAddress.streetName,
    Previous_Street_Type__c: prevManualAddress.streetType,
    Previous_Suburb__c: prevManualAddress.suburb,
    Previous_State__c: prevManualAddress.state,
    Previous_Postcode__c: prevManualAddress.postcode,
    Previous_Country__c:  region === REGION.AU ? COUNTRY.AU : COUNTRY.NZ,
    Previous_Property_Name__c: prevManualAddress.propertyName
  };

  const nzPreviousCity = {
    Previous_City__c: data.previousAddress.cantFindAutoAddress ? prevManualAddress.city : prevAutoAddress.TownCityMailtown
  };

  const nzDriverLicenceData = {
    Driver_s_License_Version__c: data.hasDriverLicence ? data.version : '',
    Driver_s_License_Expiry_Date__c: data.hasDriverLicence ? driveLicenceExpiryDate: ''
  };

  let formattedData = {
    Loan_Amount__c: loanAmount,
    Loan_Term__c: loanTermParam,
    Loan_Purpose__c: loanText[0].text,
    Other_Loan_Purpose__c: loanPurposeOther,
    Channel__c: channel,
    Region__c: region,
    Product__c: product,
    Consent_For_Soft_Enquiry__c: consent,
    Title__c: data.title,
    First_Name__c: data.firstName,
    Middle_Name__c: data.middleName,
    Last_Name__c: data.lastName,
    Date_of_Birth__c: dateOfBirth,
    Gender__c: data.gender,
    Mobile_Number__c: data.mobileNumber,
    Email_ID__c: data.email,
    Driver_s_Licence_Number__c: driversLicence,
    Years_at_Residential_Address__c: yearsAtAddress,
    Months_at_Residential_Address__c: monthsAtAddress,
    Income_Source__c: data.incomeFrom,
    Occupation__c: data.occupation,
    Years_at_Current_Employment__c: yearsOfEmployment,
    Months_at_Current_Employment__c: monthsOfEmployment,
    Residential_Status__c: data.residentialStatus,
    Broker_ID__c: data.brokerNumber,
    Quote_Privacy_Consent__c: data.consentPrivacy,
    Brokerage_Fee__c: brokerFee,
    Sub_Type__c:data.isBetterStart?"Better Start":"Standard"
  };

  formattedData = {
    ...formattedData,
    ...(data.residentialAddress.cantFindAutoAddress
      ? currentManualAddressValues
      : currentAutoAddressValues),
    ...((region === REGION.AU && lengthOfResidencyInMonths >= AU_MINIMUM_RESIDENCY_IN_MONTHS)
      ? '' : region === REGION.AU && data.previousAddress.cantFindAutoAddress
        ? prevManualAddressValues
        : data.previousAddress.autoAddress.Locality !== undefined
        ? prevAutoAddressValues
        : ''),
    ...(region === REGION.NZ
      ? nzResidentialCity
      : ''),
    ...((region === REGION.NZ && lengthOfResidencyInMonths >= GEM_MINIMUM_RESIDENCY_IN_MONTHS)
      ? '' : region === REGION.NZ && data.previousAddress.cantFindAutoAddress
        ? prevManualAddressValues
        : data.previousAddress.autoAddress.TownCityMailtown !== undefined
          ? prevAutoAddressValues
          : ''),
    ...(region === REGION.NZ && lengthOfResidencyInMonths < GEM_MINIMUM_RESIDENCY_IN_MONTHS
      ? nzPreviousCity
      : ''),
    ...(region === REGION.NZ
      ? nzDriverLicenceData
      : '')
  };


  return formattedData;
}
