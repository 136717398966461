export const streetTypeDefaultOptions = [
  { value: 'ST', label: 'Street' },
  { value: 'ROAD', label: 'Road' },
  { value: 'AVE', label: 'Avenue' },
  { value: 'CRES', label: 'Crescent' },
  { value: 'DR', label: 'Drive' },
  { value: 'PL', label: 'Place' },
  { value: 'CRT', label: 'Court' },
  { value: 'DEFLT', label: '--' },
  { value: 'ACCS', label: 'Access' },
  { value: 'ACWY', label: 'Accessway' },
  { value: 'ALWY', label: 'Alleyway' },
  { value: 'ALY', label: 'Alley' },
  { value: 'AMBL', label: 'Amble' },
  { value: 'ANCG', label: 'Anchorage' },
  { value: 'ANX', label: 'Annex' },
  { value: 'APP', label: 'Approach' },
  { value: 'ARC', label: 'Arcade' },
  { value: 'Arch', label: 'Arch' },
  { value: 'ART', label: 'Artery' },
  { value: 'Bank', label: 'Bank' },
  { value: 'BASN', label: 'Basin' },
  { value: 'Bay', label: 'Bay' },
  { value: 'BCH', label: 'Beach' },
  { value: 'BDGE', label: 'Bridge' },
  { value: 'BDWY', label: 'Broadway' },
  { value: 'Belt', label: 'Belt' },
  { value: 'BLF', label: 'Bluff' },
  { value: 'BLK', label: 'Block' },
  { value: 'BLVD', label: 'Boulevard' },
  { value: 'BND', label: 'Bend' },
  { value: 'BR', label: 'Branch' },
  { value: 'BRAE', label: 'Brae' },
  { value: 'BRC', label: 'Brace' },
  { value: 'Briar', label: 'Briars' },
  { value: 'BRK', label: 'Break' },
  { value: 'BRK', label: 'Brook' },
  { value: 'BROW', label: 'Brow' },
  { value: 'BTM', label: 'Bottom' },
  { value: 'BYPA', label: 'Bypass' },
  { value: 'BYWY', label: 'Byway' },
  { value: 'CAUS', label: 'Causeway' },
  { value: 'CCT', label: 'Circuit' },
  { value: 'CDS', label: 'Cul-De-Sac' },
  { value: 'CDS', label: 'CUL-DE-SAC' },
  { value: 'Cent', label: 'Central' },
  { value: 'CHASE', label: 'Chase' },
  { value: 'CIR', label: 'Circle' },
  { value: 'CL', label: 'Close' },
  { value: 'CLDE', label: 'Colonnade' },
  { value: 'CLFS', label: 'Cliffs' },
  { value: 'CLM', label: 'Claim' },
  { value: 'CLT', label: 'Circlet' },
  { value: 'CMN', label: 'Common' },
  { value: 'CNR', label: 'Corner' },
  { value: 'CNRS', label: 'Corners' },
  { value: 'CNWY', label: 'Centreway' },
  { value: 'CON', label: 'Concourse' },
  { value: 'COVE', label: 'Cove' },
  { value: 'COWY', label: 'Crossway' },
  { value: 'CPE', label: 'Cape' },
  { value: 'CPS', label: 'Copse' },
  { value: 'CRCS', label: 'Circus' },
  { value: 'CRD', label: 'Crossroad' },
  { value: 'CRK', label: 'Creek' },
  { value: 'CRSE', label: 'Course' },
  { value: 'CRSG', label: 'Crossing' },
  { value: 'Crso', label: 'Corseo' },
  { value: 'CRSS', label: 'Cross' },
  { value: 'CRST', label: 'Crest' },
  { value: 'CRTS', label: 'Courts' },
  { value: 'CSO', label: 'Corso' },
  { value: 'CTG', label: 'Cutting' },
  { value: 'CTR', label: 'Centre' },
  { value: 'CTYD', label: 'Courtyard' },
  { value: 'Cul', label: 'Cul' },
  { value: 'CUWY', label: 'Cruiseway' },
  { value: 'DALE', label: 'Dale' },
  { value: 'DELL', label: 'Dell' },
  { value: 'DEVN', label: 'Deviation' },
  { value: 'DIP', label: 'Dip' },
  { value: 'Downs', label: 'Downs' },
  { value: 'DRWY', label: 'Driveway' },
  { value: 'DSTR', label: 'Distributor' },
  { value: 'Dune', label: 'Dune' },
  { value: 'DV', label: 'Divide' },
  { value: 'East', label: 'East' },
  { value: 'EDGE', label: 'Edge' },
  { value: 'ELB', label: 'Elbow' },
  { value: 'Elm', label: 'Elm' },
  { value: 'END', label: 'End' },
  { value: 'ENT', label: 'Entrance' },
  { value: 'ESP', label: 'Esplanade' },
  { value: 'EST', label: 'Estate' },
  { value: 'ESTS', label: 'Estates' },
  { value: 'EXT', label: 'Extension' },
  { value: 'EXWY', label: 'Expressway' },
  { value: 'Fall', label: 'Fall' },
  { value: 'Fare', label: 'Fare' },
  { value: 'FAWY', label: 'Fairway' },
  { value: 'Fen', label: 'Fen' },
  { value: 'Fern', label: 'Fern' },
  { value: 'FITR', label: 'Firetrail' },
  { value: 'FLAT', label: 'Flat' },
  { value: 'FLD', label: 'Field' },
  { value: 'FLDS', label: 'Fields' },
  { value: 'FLS', label: 'Falls' },
  { value: 'FLTS', label: 'Flats' },
  { value: 'FOLW', label: 'Follow' },
  { value: 'FORM', label: 'Formation' },
  { value: 'FRD', label: 'Ford' },
  { value: 'FRKS', label: 'Forks' },
  { value: 'FRMS', label: 'Farms' },
  { value: 'FRNT', label: 'Front' },
  { value: 'FRST', label: 'Forest' },
  { value: 'FRTG', label: 'Frontage' },
  { value: 'FRY', label: 'Ferry' },
  { value: 'FSHR', label: 'Foreshore' },
  { value: 'FT', label: 'Fort' },
  { value: 'FTRK', label: 'FireTrack' },
  { value: 'FTWY', label: 'Footway' },
  { value: 'FWY', label: 'Freeway' },
  { value: 'GAP', label: 'Gap' },
  { value: 'GDN', label: 'Garden' },
  { value: 'GDNS', label: 'Gardens' },
  { value: 'GLD', label: 'Glade' },
  { value: 'GLN', label: 'Glen' },
  { value: 'GLY', label: 'Gully' },
  { value: 'GRG', label: 'Grange' },
  { value: 'GRN', label: 'Green' },
  { value: 'GRND', label: 'Ground' },
  { value: 'GRV', label: 'Grove' },
  { value: 'GTE', label: 'Gate' },
  { value: 'GTES', label: 'Gates' },
  { value: 'HBR', label: 'Harbour' },
  { value: 'Head', label: 'Head' },
  { value: 'Hill', label: 'Hill' },
  { value: 'HLS', label: 'Hills' },
  { value: 'HRD', label: 'Highroad' },
  { value: 'HTS', label: 'Heights' },
  { value: 'HVN', label: 'Haven' },
  { value: 'HWY', label: 'Highway' },
  { value: 'INTG', label: 'Interchange' },
  { value: 'INTN', label: 'Intersection' },
  { value: 'IS', label: 'Island' },
  { value: 'JCT', label: 'Junction' },
  { value: 'KEY', label: 'Key' },
  { value: 'Knob', label: 'Knob' },
  { value: 'LADR', label: 'Ladder' },
  { value: 'Lane', label: 'Lane' },
  { value: 'LDG', label: 'Landing' },
  { value: 'LDG', label: 'Lodge' },
  { value: 'Lea', label: 'Lea' },
  { value: 'LEDR', label: 'Leader' },
  { value: 'LEES', label: 'Lees' },
  { value: 'LGH', label: 'Leigh' },
  { value: 'LIGHS', label: 'Leights' },
  { value: 'Line', label: 'Line' },
  { value: 'Link', label: 'Link' },
  { value: 'LKT', label: 'Lookout' },
  { value: 'LN', label: 'Lanes' },
  { value: 'LNWY', label: 'Laneway' },
  { value: 'Loop', label: 'Loop' },
  { value: 'Loops', label: 'Loops' },
  { value: 'LT', label: 'Little' },
  { value: 'LWR', label: 'Lower' },
  { value: 'Mall', label: 'Mall' },
  { value: 'MDWS', label: 'Meadows' },
  { value: 'Mead', label: 'Mead' },
  { value: 'MEW', label: 'Mew' },
  { value: 'Mews', label: 'Mews' },
  { value: 'Mile', label: 'Mile' },
  { value: 'Mill', label: 'Mill' },
  { value: 'MLS', label: 'Mills' },
  { value: 'MNDR', label: 'Meander' },
  { value: 'Motu', label: 'Motu' },
  { value: 'Mount', label: 'Mount' },
  { value: 'MSN', label: 'Mission' },
  { value: 'MTN', label: 'Mountain' },
  { value: 'MWY', label: 'Motorway' },
  { value: 'NK', label: 'Nook' },
  { value: 'NVS', label: 'Neaves' },
  { value: 'Oaks', label: 'Oaks' },
  { value: 'ORCH', label: 'Orchard' },
  { value: 'OVERB', label: 'Overbridge' },
  { value: 'PADK', label: 'Paddock' },
  { value: 'Paku', label: 'Paku' },
  { value: 'PARK', label: 'Park' },
  { value: 'PART', label: 'Part' },
  { value: 'Pass', label: 'Pass' },
  { value: 'PATH', label: 'Path' },
  { value: 'PDE', label: 'Parade' },
  { value: 'PHWY', label: 'Pathway' },
  { value: 'PIAZ', label: 'Piazza' },
  { value: 'PKLD', label: 'Parklands' },
  { value: 'PKT', label: 'Pocket' },
  { value: 'Pkwy', label: 'Parkway' },
  { value: 'PLAT', label: 'Plateau' },
  { value: 'PLMS', label: 'Palms' },
  { value: 'PLZA', label: 'Plaza' },
  { value: 'POINT', label: 'POINT' },
  { value: 'Port', label: 'Port' },
  { value: 'Prior', label: 'Priors' },
  { value: 'PROM', label: 'Promenade' },
  { value: 'PSGE', label: 'Passage' },
  { value: 'QDGL', label: 'Quadrangle' },
  { value: 'QDRT', label: 'Quadrant' },
  { value: 'QUAD', label: 'Quad' },
  { value: 'QUAY', label: 'QUAY' },
  { value: 'QYS', label: 'Quays' },
  { value: 'RAMP', label: 'Ramp' },
  { value: 'RAN', label: 'Ranae' },
  { value: 'RCH', label: 'Reach' },
  { value: 'RDGE', label: 'Ridge' },
  { value: 'RD', label: 'Road' },
  { value: 'RDS', label: 'Roads' },
  { value: 'RDSD', label: 'Roadside' },
  { value: 'RDWY', label: 'Roadway' },
  { value: 'Reef', label: 'Reef' },
  { value: 'RES', label: 'Reserve' },
  { value: 'Rest', label: 'Rest' },
  { value: 'RGWY', label: 'Ridgeway' },
  { value: 'RIDE', label: 'Ride' },
  { value: 'RING', label: 'Ring' },
  { value: 'Rise', label: 'Rise' },
  { value: 'RMBL', label: 'Ramble' },
  { value: 'RND', label: 'Round' },
  { value: 'RNDE', label: 'Ronde' },
  { value: 'RNGE', label: 'Range' },
  { value: 'Row', label: 'Row' },
  { value: 'ROWY', label: 'RightOfWay' },
  { value: 'RPDS', label: 'Rapids' },
  { value: 'RSBL', label: 'Rosebowl' },
  { value: 'RTE', label: 'Route' },
  { value: 'RTR', label: 'Retreat' },
  { value: 'RTY', label: 'Rotary' },
  { value: 'Rua', label: 'Rua' },
  { value: 'RUE', label: 'Rue' },
  { value: 'RUN', label: 'Run' },
  { value: 'RVR', label: 'River' },
  { value: 'RVRA', label: 'Riviera' },
  { value: 'RVWY', label: 'Riverway' },
  { value: 'SBWY', label: 'Subway' },
  { value: 'SDNG', label: 'Siding' },
  { value: 'SHR', label: 'Shore' },
  { value: 'SHRS', label: 'Shores' },
  { value: 'SHWY', label: 'State Highway' },
  { value: 'SLPE', label: 'Slope' },
  { value: 'SMT', label: 'Summit' },
  { value: 'SND', label: 'Sound' },
  { value: 'Spa', label: 'Spa' },
  { value: 'SPG', label: 'Spring' },
  { value: 'SPGS', label: 'Springs' },
  { value: 'SPUR', label: 'Spur' },
  { value: 'SQ', label: 'Square' },
  { value: 'STA', label: 'Station' },
  { value: 'STEEP', label: 'STEEP' },
  { value: 'STGT', label: 'Straight' },
  { value: 'STPS', label: 'Steps' },
  { value: 'STRA', label: 'Straat' },
  { value: 'STRA', label: 'Strand' },
  { value: 'STRM', label: 'Stream' },
  { value: 'STRP', label: 'Strip' },
  { value: 'STRS', label: 'Stairs' },
  { value: 'SVLN', label: 'ServiceLane' },
  { value: 'SVWY', label: 'ServiceWay' },
  { value: 'SWY', label: 'Serviceway' },
  { value: 'TARN', label: 'Tarn' },
  { value: 'TCE', label: 'Terrace' },
  { value: 'TCES', label: 'Terraces' },
  { value: 'THOR', label: 'Thoroughfare' },
  { value: 'TKWY', label: 'Trunkway' },
  { value: 'TLWY', label: 'Tollway' },
  { value: 'TMWY', label: 'Tramway' },
  { value: 'TOP', label: 'Top' },
  { value: 'TOR', label: 'Tor' },
  { value: 'Tram', label: 'Tram' },
  { value: 'TRI', label: 'Triangle' },
  { value: 'TRK', label: 'Track' },
  { value: 'TRL', label: 'Trail' },
  { value: 'TRLR', label: 'Trailer' },
  { value: 'TRS', label: 'Trees' },
  { value: 'TUNL', label: 'Tunnel' },
  { value: 'TURN', label: 'Turn' },
  { value: 'TWRS', label: 'Towers' },
  { value: 'UPAS', label: 'Underpass' },
  { value: 'UPR', label: 'Upper' },
  { value: 'VALE', label: 'Vale' },
  { value: 'VDCT', label: 'Viaduct' },
  { value: 'VLG', label: 'Village' },
  { value: 'VLLS', label: 'Villas' },
  { value: 'VLY', label: 'Valley' },
  { value: 'VNUS', label: 'Venus' },
  { value: 'VSTA', label: 'Vista' },
  { value: 'Vue', label: 'Vue' },
  { value: 'VW', label: 'View' },
  { value: 'VWS', label: 'Views' },
  { value: 'WADE', label: 'Wade' },
  { value: 'Water', label: 'Waters' },
  { value: 'Way', label: 'Way' },
  { value: 'WHNA', label: 'Whenua' },
  { value: 'WHRF', label: 'Wharf' },
  { value: 'WKWY', label: 'Walkway' },
  { value: 'WLK', label: 'Walk' },
  { value: 'Wynd', label: 'Wynd' },
  { value: 'YARD', label: 'Yard' },
];
