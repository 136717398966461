export const streetTypeAuOptions = [
  { value: 'Alley', label: 'Alley'},
  { value: 'Ambl', label: 'Ambl'},
  { value: 'Arcade', label: 'Arcade'},
  { value: 'Avenue', label: 'Avenue'},
  { value: 'Bend', label: 'Bend'},
  { value: 'Boulevard', label: 'Boulevard'},
  { value: 'Brace', label: 'Brace'},
  { value: 'Centre', label: 'Centre'},
  { value: 'Chase', label: 'Chase'},
  { value: 'Circle', label: 'Circle'},
  { value: 'Circuit', label: 'Circuit'},
  { value: 'Circus', label: 'Circus'},
  { value: 'Close', label: 'Close'},
  { value: 'Concourse', label: 'Concourse'},
  { value: 'Corner', label: 'Corner'},
  { value: 'Corso', label: 'Corso'},
  { value: 'Court', label: 'Court'},
  { value: 'Crescent', label: 'Crescent'},
  { value: 'Crossing', label: 'Crossing'},
  { value: 'Drive', label: 'Drive'},
  { value: 'Entrance', label: 'Entrance'},
  { value: 'Esplanade', label: 'Esplanade'},
  { value: 'Freeway', label: 'Freeway'},
  { value: 'Frontage', label: 'Frontage'},
  { value: 'Gate', label: 'Gate'},
  { value: 'Glade', label: 'Glade'},
  { value: 'Glen', label: 'Glen'},
  { value: 'Grange', label: 'Grange'},
  { value: 'Green', label: 'Green'},
  { value: 'Ground', label: 'Ground'},
  { value: 'Grove', label: 'Grove'},
  { value: 'Heights', label: 'Heights'},
  { value: 'Highway', label: 'Highway'},
  { value: 'Junction', label: 'Junction'},
  { value: 'Lane', label: 'Lane'},
  { value: 'Link', label: 'Link'},
  { value: 'Loop', label: 'Loop'},
  { value: 'Mews', label: 'Mews'},
  { value: 'Mount', label: 'Mount'},
  { value: 'Nook', label: 'Nook'},
  { value: 'Outlook', label: 'Outlook'},
  { value: 'Parade', label: 'Parade'},
  { value: 'Parkway', label: 'Parkway'},
  { value: 'Pass', label: 'Pass'},
  { value: 'Place', label: 'Place'},
  { value: 'Plaza', label: 'Plaza'},
  { value: 'Point', label: 'Point'},
  { value: 'Port', label: 'Port'},
  { value: 'Promenade', label: 'Promenade'},
  { value: 'Quadrangle', label: 'Quadrangle'},
  { value: 'Quadrant', label: 'Quadrant'},
  { value: 'Quay', label: 'Quay'},
  { value: 'Reserve', label: 'Reserve'},
  { value: 'Rest', label: 'Rest'},
  { value: 'Retreat', label: 'Retreat'},
  { value: 'Ridge', label: 'Ridge'},
  { value: 'Right of Way', label: 'Right of Way'},
  { value: 'Rise', label: 'Rise'},
  { value: 'Road', label: 'Road'},
  { value: 'Roadway', label: 'Roadway'},
  { value: 'Round', label: 'Round'},
  { value: 'Row', label: 'Row'},
  { value: 'Siding', label: 'Siding'},
  { value: 'Square', label: 'Square'},
  { value: 'Street', label: 'Street'},
  { value: 'Terrace', label: 'Terrace'},
  { value: 'Tollway', label: 'Tollway'},
  { value: 'Track', label: 'Track'},
  { value: 'View', label: 'View'},
  { value: 'Walk', label: 'Walk'},
  { value: 'Walkway', label: 'Walkway'},
  { value: 'Way', label: 'Way'},
  { value: 'Wynd', label: 'Wynd'},
];
