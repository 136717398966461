export const residentialStatusOptions = [
  {
    value: 'Renting',
    label: 'Renting',
  },
  {
    value: 'Boarding with Parents',
    label: 'Boarding with Parents',
  },
  {
    value: 'Own a home with a mortgage',
    label: "Own a home with a mortgage",
  },
  {
    value: 'Own a home outright',
    label: "Own a home outright",
  },
  {
    value: 'Supplied by employer',
    label: 'Supplied by employer',
  },
  {
    value: 'Boarding Other',
    label: 'Boarding Other',
  },
];
