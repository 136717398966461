import React from 'react';
import { Container, Sticky } from 'basis';
import { SoftQuoteHeader } from '@/components/PersonalLoanSoftQuote/Header';
import {SimplifiedFooter} from '@/components/PersonalLoanSoftQuote/SimplifiedFooter';

export const FormLayout = ({ children, product }) => (
  <Sticky>
    <Sticky.Item>
      <SoftQuoteHeader product={product}/>
    </Sticky.Item>
    <Container
      bg="grey.t03"
      padding="0"
      padding-lg="1"
      margin="0 0 0 0"
      margin-md="0 0 0 0"
    >
      <Container
        padding="0"
        margin="0"
        margin-md="0"
        margin-lg="8 auto 7"
        hasBreakpointWidth={false}
        hasBreakpointWidth-lg
      >
        <Container
          bg="white"
          padding="6 0"
          padding-xs="6 0"
          padding-md="6 0"
          padding-lg="6 0"
          hasBorder
          margin="0"
        >
          {children}
        </Container>
      </Container>
    </Container>
    <SimplifiedFooter product={product}/>
  </Sticky>
);
