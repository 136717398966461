import axios from 'axios';
import {getDatatoolsBaseUrl, getLfsAddressTokenBaseUrl} from '@/utils/getUrlFromEnvUtil';
import {REQUEST_CHANNEL} from '@/components/PersonalLoanSoftQuote/constants/softQuoteConstants';

const GATSBY_LFS_ADDRESS_TOKEN_GENERATOR_BASE_URL = getLfsAddressTokenBaseUrl();
const GATSBY_KLEBER_DATA_TOOLS_BASE_URL = getDatatoolsBaseUrl();

export function getMethodWrapper(url) {
  return axios.get(url, {
    headers: {
      'Content-Type': 'application/json',
    },
  });
}

export function getMethodWrapperWithCorsHeader(url, requestChannel) {
  return axios.get(url, {
    headers: {
      'LFS-Request-Channel': requestChannel,
    },
  });
}

export function generateTemporaryToken(requestChannel = REQUEST_CHANNEL.LFComAu) {
  const ADDRESS_TOKEN_BASE_URL =
    GATSBY_LFS_ADDRESS_TOKEN_GENERATOR_BASE_URL;
  return getMethodWrapperWithCorsHeader(`${ADDRESS_TOKEN_BASE_URL}/token`, requestChannel);
}

export function searchAddresses(
  temporaryRequestKey,
  addressLineQuery,
  countryCode,
) {
  const encodedTemporaryRequestKey = encodeURIComponent(temporaryRequestKey);
  const SPACE = ' ';
  const addressLineParam = `AddressLine=${addressLineQuery.replace(
    SPACE,
    '+',
  )}`;
  const resultLimitParam = 'ResultLimit=10';
  const displayOnlyCountryCodeParam = `DisplayOnlyCountryCode=${countryCode}`;
  const searchOptionParam = 'SearchOption=';
  const requestIdParam = 'RequestId=';
  const requestKeyParam = `RequestKey=${encodedTemporaryRequestKey}`;
  const departmentCodeParam = 'DepartmentCode=';
  const paramAppender = '&';
  const outputFormatJSONParam = 'OutputFormat=json';
  const queryString =
    'ProcessQueryStringRequest?Method=DataTools.Capture.Address.Predictive.AuNzPaf.SearchAddress';
  const urlQueryString =
    queryString +
    paramAppender +
    addressLineParam +
    paramAppender +
    resultLimitParam +
    paramAppender +
    displayOnlyCountryCodeParam +
    paramAppender +
    searchOptionParam +
    paramAppender +
    requestIdParam +
    paramAppender +
    requestKeyParam +
    paramAppender +
    departmentCodeParam +
    paramAppender +
    outputFormatJSONParam;
  return getMethodWrapper(`${GATSBY_KLEBER_DATA_TOOLS_BASE_URL}/${urlQueryString}`);
}

export function getSelectedAddress(temporaryRequestKey, recordId, countryCode) {
  const encodedTemporaryRequestKey = encodeURIComponent(temporaryRequestKey);
  const recordIdParam = `RecordId=${recordId}`;
  const requestIdParam = 'RequestId=';
  const paramAppender = '&';
  const requestKeyParam = `RequestKey=${encodedTemporaryRequestKey}`;
  const departmentCodeParam = 'DepartmentCode=';
  const outputFormatJSONParam = 'OutputFormat=json';
  const queryString =
    countryCode === 'AU'
      ? 'ProcessQueryStringRequest?Method=DataTools.Capture.Address.Predictive.AuPaf.RetrieveAddress'
      : 'ProcessQueryStringRequest?Method=DataTools.Capture.Address.Predictive.NzPaf.RetrieveAddress';

  const urlQueryString =
    queryString +
    paramAppender +
    recordIdParam +
    paramAppender +
    requestIdParam +
    paramAppender +
    requestKeyParam +
    paramAppender +
    departmentCodeParam +
    paramAppender +
    outputFormatJSONParam;

  return getMethodWrapper(`${GATSBY_KLEBER_DATA_TOOLS_BASE_URL}/${urlQueryString}`);
}
