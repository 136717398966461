import {REGION} from "../constants/softQuoteConstants";
import {streetTypeAuOptions} from "../constants/streetTypeAUList";
import {streetTypeNzOptions} from "../constants/streetTypeNZList";

export function getStreetAddress(address, region = REGION.AU) {
  let street = {streetName: address.StreetName, streetType: ''};
  let streetTypes = region === REGION.AU ? streetTypeAuOptions : streetTypeNzOptions;

  streetTypes.map(({ value }) => {
    if (address.StreetName.includes(value)) {
      street.streetType = value;
      street.streetName = address.StreetName.replace(value, '').trim();
    }
  })
  return street;
}
