import React from 'react';
import styled from '@emotion/styled';
import { Stepper } from 'basis';

const StepperWrapper = styled.div`
  z-index: 0;
  background-color: red;
`;

function ProgressStepper({ currentLabel }) {
  return (
    <StepperWrapper>
      <Stepper>
        <Stepper.Item
          label="About"
          current={currentLabel === 'About'}
        />
        <Stepper.Item
          label="Employment"
          current={currentLabel === 'Employment'}
        />
        <Stepper.Item
          label="Your quote"
          current={currentLabel === 'Your quote'}
        />
      </Stepper>
    </StepperWrapper>
  );
}

export { ProgressStepper };
