/* eslint-disable no-console */

import {getSelectedAddress} from "@/components/PersonalLoanSoftQuote/library/addressApi";
import {RESIDENTIAL_ADDRESS_TYPE} from "@/components/PersonalLoanSoftQuote/constants/softQuoteConstants";
import {getStreetAddress} from "@/components/PersonalLoanSoftQuote/utils/parseAddressSpecialStreetAddress";
import {residentialAddressServerErrorMessage} from "@/components/PersonalLoanSoftQuote/validator/addressValidator";
import {
  displayAutoAddressErrorIfAddressInvalid,
} from "@/components/PersonalLoanSoftQuote/utils/addressUtils";
import {getEnvForDebug} from "@/utils/getUrlFromEnvUtil";

const _ = require('lodash');

const DEBUG = getEnvForDebug();

export const onSelectAutoAddress = (state, addressType, selectedItem, addressToken, setAutoAddressErrorMessage, setHasResidentialAddressAutoAddressError, region) => {
  if (selectedItem) {
    getSelectedAddress(addressToken, selectedItem.RecordId, region)
      .then(address => {
        let autoAddress = {};
        if (addressType === RESIDENTIAL_ADDRESS_TYPE.Current) {
          autoAddress = state.formValues.residentialAddress.autoAddress =
            address.data.DtResponse.Result[0];
        } else {
          autoAddress = state.formValues.previousAddress.autoAddress =
            address.data.DtResponse.Result[0];
        }
       if(DEBUG) console.log(autoAddress)

        let streetAddress = {};
        if(_.isEmpty(autoAddress.StreetType)) {
          streetAddress = getStreetAddress(autoAddress, region);
          autoAddress.StreetName = streetAddress.streetName;
          autoAddress.StreetType = streetAddress.streetType;
        }
        displayAutoAddressErrorIfAddressInvalid(autoAddress, setAutoAddressErrorMessage, setHasResidentialAddressAutoAddressError, region);
      })
      .catch(error => {
        if(DEBUG) console.log(error);
        setHasResidentialAddressAutoAddressError(true);
        setAutoAddressErrorMessage(residentialAddressServerErrorMessage);
      });
  }
};


