export function getItemWithExpiry(key) {
  let value;
  if (typeof window !== `undefined`) {
    value =
      window && window.localStorage.getItem(key)
        ? JSON.parse(window.localStorage.getItem(key))
        : null;
  }

  // data not found
  if (!value) {
    return null;
  }

  const { timestamp, expiry, data } = value;

  // only return data if delta of current time and timestamp is within expiry
  return timestamp && expiry && Date.now() - timestamp <= expiry ? data : null;
}

export function setItemWithExpiry(value, key, expiry) {
  if (window) {
    window.localStorage.setItem(
      key,
      JSON.stringify({ data: value, timestamp: Date.now(), expiry }),
    );
  }
}
