export const streetTypeNzOptions = [
  { value: 'Access', label: 'Access'},
  { value: 'Accessway', label: 'Accessway'},
  { value: 'Alley', label: 'Alley'},
  { value: 'Alleyway', label: 'Alleyway'},
  { value: 'Amble', label: 'Amble'},
  { value: 'Anchorage', label: 'Anchorage'},
  { value: 'Annex', label: 'Annex'},
  { value: 'Approach', label: 'Approach'},
  { value: 'Arcade', label: 'Arcade'},
  { value: 'Arch', label: 'Arch'},
  { value: 'Artery', label: 'Artery'},
  { value: 'Avenue', label: 'Avenue'},
  { value: 'Bank', label: 'Bank'},
  { value: 'Basin', label: 'Basin'},
  { value: 'Bay', label: 'Bay'},
  { value: 'Beach', label: 'Beach'},
  { value: 'Belt', label: 'Belt'},
  { value: 'Bend', label: 'Bend'},
  { value: 'Block', label: 'Block'},
  { value: 'Bluff', label: 'Bluff'},
  { value: 'Bottom', label: 'Bottom'},
  { value: 'Boulevard', label: 'Boulevard'},
  { value: 'Brace', label: 'Brace'},
  { value: 'Brae', label: 'Brae'},
  { value: 'Branch', label: 'Branch'},
  { value: 'Break', label: 'Break'},
  { value: 'Briar', label: 'Briar'},
  { value: 'Bridge', label: 'Bridge'},
  { value: 'Broadway', label: 'Broadway'},
  { value: 'Brook', label: 'Brook'},
  { value: 'Brow', label: 'Brow'},
  { value: 'Bypass', label: 'Bypass'},
  { value: 'Byway', label: 'Byway'},
  { value: 'Cape', label: 'Cape'},
  { value: 'Causeway', label: 'Causeway'},
  { value: 'Centre', label: 'Centre'},
  { value: 'Centreway', label: 'Centreway'},
  { value: 'Chase', label: 'Chase'},
  { value: 'Circle', label: 'Circle'},
  { value: 'Circlet', label: 'Circlet'},
  { value: 'Circuit', label: 'Circuit'},
  { value: 'Circus', label: 'Circus'},
  { value: 'Claim', label: 'Claim'},
  { value: 'Cliffs', label: 'Cliffs'},
  { value: 'Close', label: 'Close'},
  { value: 'Colonnade', label: 'Colonnade'},
  { value: 'Common', label: 'Common'},
  { value: 'Concourse', label: 'Concourse'},
  { value: 'Copse', label: 'Copse'},
  { value: 'Corner', label: 'Corner'},
  { value: 'Corso', label: 'Corso'},
  { value: 'Course', label: 'Course'},
  { value: 'Court', label: 'Court'},
  { value: 'Courts', label: 'Courts'},
  { value: 'Courtyard', label: 'Courtyard'},
  { value: 'Cove', label: 'Cove'},
  { value: 'Creek', label: 'Creek'},
  { value: 'Crescent', label: 'Crescent'},
  { value: 'Crest', label: 'Crest'},
  { value: 'Cross', label: 'Cross'},
  { value: 'Crossing', label: 'Crossing'},
  { value: 'Crossroad', label: 'Crossroad'},
  { value: 'Crossway', label: 'Crossway'},
  { value: 'Crso', label: 'Crso'},
  { value: 'Cruiseway', label: 'Cruiseway'},
  { value: 'Cul', label: 'Cul'},
  { value: 'Cutting', label: 'Cutting'},
  { value: 'Dale', label: 'Dale'},
  { value: 'Dell', label: 'Dell'},
  { value: 'Deviation', label: 'Deviation'},
  { value: 'Dip', label: 'Dip'},
  { value: 'Distributor', label: 'Distributor'},
  { value: 'Divide', label: 'Divide'},
  { value: 'Downs', label: 'Downs'},
  { value: 'Drive', label: 'Drive'},
  { value: 'Driveway', label: 'Driveway'},
  { value: 'Dune', label: 'Dune'},
  { value: 'Edge', label: 'Edge'},
  { value: 'Elbow', label: 'Elbow'},
  { value: 'Elm', label: 'Elm'},
  { value: 'End', label: 'End'},
  { value: 'Entrance', label: 'Entrance'},
  { value: 'Esplanade', label: 'Esplanade'},
  { value: 'Estate', label: 'Estate'},
  { value: 'Expressway', label: 'Expressway'},
  { value: 'Extension', label: 'Extension'},
  { value: 'Fairway', label: 'Fairway'},
  { value: 'Fall', label: 'Fall'},
  { value: 'Fare', label: 'Fare'},
  { value: 'Farms', label: 'Farms'},
  { value: 'Fen', label: 'Fen'},
  { value: 'Fern', label: 'Fern'},
  { value: 'Ferry', label: 'Ferry'},
  { value: 'Field', label: 'Field'},
  { value: 'Fire Track', label: 'Fire Track'},
  { value: 'Firetrail', label: 'Firetrail'},
  { value: 'Flat', label: 'Flat'},
  { value: 'Flats', label: 'Flats'},
  { value: 'Follow', label: 'Follow'},
  { value: 'Footway', label: 'Footway'},
  { value: 'Ford', label: 'Ford'},
  { value: 'Foreshore', label: 'Foreshore'},
  { value: 'Forest', label: 'Forest'},
  { value: 'Forks', label: 'Forks'},
  { value: 'Formation', label: 'Formation'},
  { value: 'Fort', label: 'Fort'},
  { value: 'Freeway', label: 'Freeway'},
  { value: 'Front', label: 'Front'},
  { value: 'Frontage', label: 'Frontage'},
  { value: 'Gap', label: 'Gap'},
  { value: 'Garden', label: 'Garden'},
  { value: 'Gardens', label: 'Gardens'},
  { value: 'Gate', label: 'Gate'},
  { value: 'Glade', label: 'Glade'},
  { value: 'Glen', label: 'Glen'},
  { value: 'Grange', label: 'Grange'},
  { value: 'Green', label: 'Green'},
  { value: 'Ground', label: 'Ground'},
  { value: 'Grove', label: 'Grove'},
  { value: 'Gully', label: 'Gully'},
  { value: 'Harbour', label: 'Harbour'},
  { value: 'Haven', label: 'Haven'},
  { value: 'Head', label: 'Head'},
  { value: 'Heights', label: 'Heights'},
  { value: 'Highroad', label: 'Highroad'},
  { value: 'Highway', label: 'Highway'},
  { value: 'Hill', label: 'Hill'},
  { value: 'Hills', label: 'Hills'},
  { value: 'Interchange', label: 'Interchange'},
  { value: 'Intersection', label: 'Intersection'},
  { value: 'Island', label: 'Island'},
  { value: 'Junction', label: 'Junction'},
  { value: 'Key', label: 'Key'},
  { value: 'Knob', label: 'Knob'},
  { value: 'Ladder', label: 'Ladder'},
  { value: 'Landing', label: 'Landing'},
  { value: 'Lane', label: 'Lane'},
  { value: 'Laneway', label: 'Laneway'},
  { value: 'Lea', label: 'Lea'},
  { value: 'Leader', label: 'Leader'},
  { value: 'Leigh', label: 'Leigh'},
  { value: 'LIGHS', label: 'LIGHS'},
  { value: 'Line', label: 'Line'},
  { value: 'Link', label: 'Link'},
  { value: 'Little', label: 'Little'},
  { value: 'Lodge', label: 'Lodge'},
  { value: 'Lookout', label: 'Lookout'},
  { value: 'Loop', label: 'Loop'},
  { value: 'Lower', label: 'Lower'},
  { value: 'Mall', label: 'Mall'},
  { value: 'Mead', label: 'Mead'},
  { value: 'Meadows', label: 'Meadows'},
  { value: 'Meander', label: 'Meander'},
  { value: 'Mews', label: 'Mews'},
  { value: 'Mile', label: 'Mile'},
  { value: 'Mill', label: 'Mill'},
  { value: 'Mission', label: 'Mission'},
  { value: 'Motorway', label: 'Motorway'},
  { value: 'Motu', label: 'Motu'},
  { value: 'Mount', label: 'Mount'},
  { value: 'Mountain', label: 'Mountain'},
  { value: 'Neaves', label: 'Neaves'},
  { value: 'Nook', label: 'Nook'},
  { value: 'Oaks', label: 'Oaks'},
  { value: 'Orchard', label: 'Orchard'},
  { value: 'Overbridge', label: 'Overbridge'},
  { value: 'Paddock', label: 'Paddock'},
  { value: 'Paku', label: 'Paku'},
  { value: 'Palms', label: 'Palms'},
  { value: 'Parade', label: 'Parade'},
  { value: 'Park', label: 'Park'},
  { value: 'Parklands', label: 'Parklands'},
  { value: 'Parkway', label: 'Parkway'},
  { value: 'Part', label: 'Part'},
  { value: 'Pass', label: 'Pass'},
  { value: 'Passage', label: 'Passage'},
  { value: 'Path', label: 'Path'},
  { value: 'Pathway', label: 'Pathway'},
  { value: 'Piaza', label: 'Piaza'},
  { value: 'Place', label: 'Place'},
  { value: 'Plateau', label: 'Plateau'},
  { value: 'Plaza', label: 'Plaza'},
  { value: 'Pocket', label: 'Pocket'},
  { value: 'Point', label: 'Point'},
  { value: 'Port', label: 'Port'},
  { value: 'Prior', label: 'Prior'},
  { value: 'Promenade', label: 'Promenade'},
  { value: 'Quad', label: 'Quad'},
  { value: 'Quadrangle', label: 'Quadrangle'},
  { value: 'Quadrant', label: 'Quadrant'},
  { value: 'Quay', label: 'Quay'},
  { value: 'Ramble', label: 'Ramble'},
  { value: 'Ramp', label: 'Ramp'},
  { value: 'Ranae', label: 'Ranae'},
  { value: 'Range', label: 'Range'},
  { value: 'Rapids', label: 'Rapids'},
  { value: 'Reach', label: 'Reach'},
  { value: 'Reef', label: 'Reef'},
  { value: 'Reserve', label: 'Reserve'},
  { value: 'Rest', label: 'Rest'},
  { value: 'Retreat', label: 'Retreat'},
  { value: 'Ride', label: 'Ride'},
  { value: 'Ridge', label: 'Ridge'},
  { value: 'Right of Way', label: 'Right of Way'},
  { value: 'Ring', label: 'Ring'},
  { value: 'Rise', label: 'Rise'},
  { value: 'River', label: 'River'},
  { value: 'Riviera', label: 'Riviera'},
  { value: 'Road', label: 'Road'},
  { value: 'Roadside', label: 'Roadside'},
  { value: 'Roadway', label: 'Roadway'},
  { value: 'Ronde', label: 'Ronde'},
  { value: 'Rosebowl', label: 'Rosebowl'},
  { value: 'Rotary', label: 'Rotary'},
  { value: 'Round', label: 'Round'},
  { value: 'Route', label: 'Route'},
  { value: 'Row', label: 'Row'},
  { value: 'Rua', label: 'Rua'},
  { value: 'Rue', label: 'Rue'},
  { value: 'Run', label: 'Run'},
  { value: 'Service Lane', label: 'Service Lane'},
  { value: 'Service Way', label: 'Service Way'},
  { value: 'Shore', label: 'Shore'},
  { value: 'Siding', label: 'Siding'},
  { value: 'Slope', label: 'Slope'},
  { value: 'Sound', label: 'Sound'},
  { value: 'Spa', label: 'Spa'},
  { value: 'Spring', label: 'Spring'},
  { value: 'Spur', label: 'Spur'},
  { value: 'Square', label: 'Square'},
  { value: 'Stairs', label: 'Stairs'},
  { value: 'Station', label: 'Station'},
  { value: 'Steep', label: 'Steep'},
  { value: 'Steps', label: 'Steps'},
  { value: 'Straat', label: 'Straat'},
  { value: 'Straight', label: 'Straight'},
  { value: 'Strand', label: 'Strand'},
  { value: 'Stream', label: 'Stream'},
  { value: 'Stream Strip', label: 'Stream Strip'},
  { value: 'Street', label: 'Street'},
  { value: 'Subway', label: 'Subway'},
  { value: 'Summit', label: 'Summit'},
  { value: 'Tarn', label: 'Tarn'},
  { value: 'TCES', label: 'TCES'},
  { value: 'Terrace', label: 'Terrace'},
  { value: 'Thoroughfare', label: 'Thoroughfare'},
  { value: 'Tollway', label: 'Tollway'},
  { value: 'Top', label: 'Top'},
  { value: 'Tor', label: 'Tor'},
  { value: 'Towers', label: 'Towers'},
  { value: 'Track', label: 'Track'},
  { value: 'Trail', label: 'Trail'},
  { value: 'Trailer', label: 'Trailer'},
  { value: 'Tram', label: 'Tram'},
  { value: 'Tramway', label: 'Tramway'},
  { value: 'Trees', label: 'Trees'},
  { value: 'Triangle', label: 'Triangle'},
  { value: 'Trunkway', label: 'Trunkway'},
  { value: 'Tunnel', label: 'Tunnel'},
  { value: 'Turn', label: 'Turn'},
  { value: 'Underpass', label: 'Underpass'},
  { value: 'Upper', label: 'Upper'},
  { value: 'Vale', label: 'Vale'},
  { value: 'Valley', label: 'Valley'},
  { value: 'Venus', label: 'Venus'},
  { value: 'Viaduct', label: 'Viaduct'},
  { value: 'View', label: 'View'},
  { value: 'Views', label: 'Views'},
  { value: 'Village', label: 'Village'},
  { value: 'Villas', label: 'Villas'},
  { value: 'Vista', label: 'Vista'},
  { value: 'Vue', label: 'Vue'},
  { value: 'Wade', label: 'Wade'},
  { value: 'Walk', label: 'Walk'},
  { value: 'Walkway', label: 'Walkway'},
  { value: 'Water', label: 'Water'},
  { value: 'Way', label: 'Way'},
  { value: 'Wharf', label: 'Wharf'},
  { value: 'Whenua', label: 'Whenua'},
  { value: 'Wynd', label: 'Wynd'},
  { value: 'Yard', label: 'Yard'}
];
