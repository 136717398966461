export const incomeFromOptions = [
  {
    value: 'My permanent - full time job',
    label: 'My permanent - full time job',
  },
  {
    value: 'My permanent - part time job',
    label: 'My permanent - part time job',
  },
  {
    value: 'My casual/temporary job',
    label: 'My casual/temporary job',
  },
  {
    value: 'My contracting job',
    label: 'My contracting job',
  },
  {
    value: 'My seasonal job',
    label: 'My seasonal job',
  },
  {
    value: 'My self-employed business',
    label: 'My self-employed business',
  },
  {
    value: 'My pension',
    label: 'My pension',
  },
  {
    value: 'My workers compensation',
    label: 'My workers compensation',
  },
  {
    value: 'My rental property',
    label: 'My rental property',
  },
  {
    value: 'My child support',
    label: 'My child support',
  },
  {
    value: 'My other source of income',
    label: 'My other source of income',
  },
];

export const incomeFromNzOptions = [
  {
    value: 'Net Salary/Wages',
    label: 'Net Salary/Wages',
  },
  {
    value: 'Rental Income',
    label: 'Rental Income',
  },
  {
    value: 'Self Employed',
    label: 'Self Employed',
  },
  {
    value: 'Government Benefit',
    label: 'Government Benefit',
  },
  {
    value: 'Other',
    label: 'Other',
  }
];
