import React from 'react';
import { Flex, Stack, Text } from 'basis';
import { COLOR, MARGIN, PADDING } from '@/utils/constants';
import ConfettiText from '@/components/ConfettiText/ConfettiText';
import { Box } from '@/components/Box/Box';

function PersonalLoanSoftQuoteHeader({broker}) {
  const title = broker ? "Check your client's rate" : "Check your rate";
  const confettiText = broker ? "This won't affect their credit score" : "This won't affect your credit score";
  return (
    <Flex placeItems="top center" margin="0">
      <Stack gap="1" align="center">
        {}
        <Text as="h3" textStyle="heading3" align="center">
          {title}
        </Text>
        <Box
          marginTop={MARGIN.M16}
          paddingLeft={PADDING.P8}
          paddingRight={PADDING.P8}
        >
          <ConfettiText
            size="Large"
            imageWidth="70px"
            imageHeight="56px"
            imageTop="-30px"
            textPaddingLeftXs="-20px"
            textBackgroundColor={COLOR.PINK_BABY}
            text={confettiText}
          />{' '}
        </Box>
      </Stack>
    </Flex>
  );
}

export { PersonalLoanSoftQuoteHeader };
